<template>
  <div class="content_container" v-if='num'>
    <el-steps :active="num" finish-status="success" simple style="margin-top: 20px">
      <el-step :title="(refund.return_type==2|| order.order_status_id == 3 )? '买家申请退货退款' : '买家申请退款'"></el-step>
      <el-step :title="(refund.return_type==2|| order.order_status_id == 3 )?'卖家处理退货退款申请': '卖家处理退款申请' "></el-step>
      <el-step :title="(refund.return_type==2|| order.order_status_id == 3 )? '拒绝退货退款' : '拒绝退款'" v-if='(order.getrefund||{}).seller_state==3'></el-step>

      <el-step title="退货拒退款" v-if="((order.getrefund || {}).refund_state == 7||(order.getrefund || {}).seller_state == 7)"></el-step>

      <el-step :title="(refund.return_type==2|| order.order_status_id == 3 )? '退货退款成功' : '退款成功'"  v-if="(order.getrefund || {}).seller_state ==2"></el-step>

    </el-steps>
    <div class="refund border">
      <el-form v-if="num == 1" ref="refund1" :rules="rules1" :model="refund1" label-width="100px" class="refund1">

        <el-form-item v-if="refund.return_type==2" label="服务类型" :rows="10">
          <el-radio v-model="refund_type" :label="1">仅退款</el-radio>
          <el-radio v-model="refund_type" :label="2">退货退款</el-radio>
        </el-form-item>

        <el-form-item label="原因" prop="reason_id" :rows="10">
          <el-select v-model="refund1.reason_id" filterable placeholder="请选择">
            <el-option v-for="item in menuItems1" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item label="退款金额" prop="price" :rows="10">
          <el-input type="text" v-model="refund_amount" autocomplete="off" placeholder="请输入退款金额"></el-input>
        </el-form-item>
        <el-form-item v-if='refund1.order_status_id==13&&!getrefund
' label="退货方式" :rows="10">
          {{ (refund1.method||{}).name }}
        </el-form-item>
        <el-form-item label="退款说明" prop="explain" :rows="10">
          <el-input type="textarea" v-model="refund1.explain" autocomplete="off" placeholder="请输入退款说明"></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="files">
          <uploader field="img" v-model="files" exts="png||jpg||gif" value-type="id" />
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 200px" type="primary" size="medium" @click="submitForm1('refund1')">提交</el-button>
        </el-form-item>
      </el-form>

      <div v-else-if="num == 2||num == 3" class="refunding">

        <table class="list">
          <tr>
            <td>退款编号：</td>
            <td>
              {{ order.getrefund.refund_sn }}
            </td>
          </tr>
          <tr>
            <td>退款金额：</td>
            <td>
              ￥{{ order.total / 100 }}
            </td>
          </tr>

          <tr>
            <td>凭证：</td>
            <td v-if='refund.pic_info[0]'>

              <el-image :src='img2' v-for="(o,i) in refund.pic_info" :key='i' :preview-src-list="srcList1" style="width:80px;height:80px;margin-right:10px;float:left">
              </el-image>

            </td>
            <td v-else>无</td>
          </tr>
          <tr>
            <td>原因：</td>
            <td>
              {{order.getrefund.reason_info}}

            </td>
          </tr>

          <tr>
            <td>要求：</td>
            <td>
              <span v-if="refund.seller_state==1&&refund.return_type!=2">退款</span>
              <span v-else>退货退款</span>
            </td>
          </tr>
          <tr>
            <td>货物状态：</td>
            <td>
              <span v-if="order.order_status_id != 3">未发货 </span>
              <span v-else> 已发货 </span>
            </td>
          </tr>
          <tr>
            <td>说明：</td>
            <td>
              <span v-if='order.getrefund.buyer_message'>
                {{ order.getrefund.buyer_message }}

              </span>
            </td>
          </tr>

        </table>
        <div class="result">
          <template v-if="((order.getrefund||{}).seller_state == 5&&(!(order.getrefund||{}).express_id))">

            <p style="font-weight: bold; font-size: 16px">
              商家同意退货<span class="themefont" style="margin-left: 10px">{{countdown}} </span>
            </p>

            <p>商家已同意退货退款，请尽快填写退货物流信息</p>

            <el-form>
              <el-form-item label="退货地址" label-width="80" prop="shipping_method"  v-if="order.getrefund.seller_address ">
              <template v-if="order.getrefund.seller_address ">
           
            <table>

              <tr>
                <td style="width:100px">收货人</td>
                <td>{{ order.getrefund.seller_address.receiver }}</td>
              </tr>
              <tr>
                <td>联系电话</td>
                <td>{{ order.getrefund.seller_address.tel }}</td>
              </tr>
              <tr>
                <td>地址</td>
                <td> {{ (order.getrefund.seller_address.get_region || {}).name +
                  ' ' +
                  (order.getrefund.seller_address.getcity || {}).name +
                  ' ' +
                  (order.getrefund.seller_address.getarea || { name: '' }).name +
                  ' ' +
                  (order.getrefund.seller_address.getstreet || { name: '' }).name +
                  ' ' + order.getrefund.seller_address.address }}</td>
              </tr>

            </table>
          </template>

              </el-form-item>
              <el-form-item label="物流公司" label-width="80" prop="shipping_method">
                <el-select v-model="shipping_method" placeholder="请选择" style="width: 80%">
                  <el-option v-for="item in logistics1" :key="item.value" :label="item.label" :value="item.label " />
                </el-select>
              </el-form-item>
              <el-form-item label="物流单号" label-width="80" prop="shipping_code">
                <el-input style="width: 80%" v-model="shipping_code" autocomplete="off" />
              </el-form-item>
              <el-form-item label-width="80" style="text-algin: center">
                <el-button type="primary" style="margin-left: 200px; width: 100px" @click="submitForm2">
                  提交
                </el-button>
              </el-form-item>
              <p>您还可以 <span class="themefont hover" @click="delRefund"> 撤销申请</span></p>
            </el-form>

            <p style="color: #999">商家同意或者超时未处理，系统将自动退货</p>

          </template>
          <template v-if="(((order.getrefund||{}).seller_state == 5&&order.getrefund.express_id))||(order.getrefund || {}).refund_state == 4||(order.getrefund||{}).seller_state == 7">

            <p style="font-weight: bold; font-size: 16px">商家已同意退货退款，您已经填写退货物流信息</p>

            <el-form>
              <el-form-item style="margin-bottom:0" label="退货地址" label-width="80" prop="shipping_method">
                {{(order.getrefund||{}).seller_address}}

              </el-form-item>
              <el-form-item style="margin-bottom:0" label="物流公司" label-width="80" prop="shipping_method">
                {{
                order.getrefund.express_id
              }}

              </el-form-item>
              <el-form-item style="margin-bottom:0" label="物流单号" label-width="80" prop="shipping_code"> {{ order.getrefund.invoice_no }}

              </el-form-item>
              <el-form-item style="margin-bottom:0" label-width="80" prop="shipping_code">
                <el-button style="margin-left:200px" type="primary" @click="logistics(order)">
                  查看物流
                </el-button>

              </el-form-item>

            </el-form>

          </template>

          <template v-if='(order.getrefund||{}).seller_state==1'>
            <div>
              <table>
                <tr>
                  <td v-if='(order.getrefund || {}).refund_type!=2 '>
                    <p style="font-weight: bold; font-size: 16px">
                      请等待商家处理<span class="themefont" style="margin-left: 10px">{{countdown}}</span>
                    </p>
                    <p>您已成功发起退款申请，请耐心等待商家处理</p>
                  </td>
                  <td v-else>
                    <p style="font-weight: bold; font-size: 16px">
                      请等待商家处理<span class="themefont" style="margin-left: 10px">{{countdown}}</span>
                    </p>
                    <p>您已成功发起退货退款申请，请耐心等待商家处理</p>
                  </td>

                </tr>
              </table>
            </div>

            <p style="color: #999">商家同意或者超时未处理，系统将退款给您</p>
            <!-- <p style="color: #999">如果商家拒绝，您可以再次发起申请</p> -->

            <p>您还可以 <span class="themefont hover" @click="delRefund"> 撤销申请</span></p>

          </template>

          <template v-if='(order.getrefund||{}).seller_state==3||(order.getrefund||{}).seller_state==7'>

            <p style="font-weight: bold; font-size: 16px">
              商家处理结果:<span class="themefont" style="margin-left: 10px">不同意 </span>
            </p>

            <p>原因：{{order.getrefund.admin_message}}</p>
            <p>图片：
              <el-image :src='img' v-if='srcList' style="width: 100px; height: 100px" :preview-src-list="srcList">
              </el-image>
              <span v-else>无</span>
            </p>

            <template v-if='num!=3'>

              <p style="color: #999">商家同意或者超时未处理，系统将退款给您</p>
            </template>
            <!-- <p style="font-weight: bold; font-size: 16px" v-if='(order.getrefund||{}).refund_state==5'>

              平台处理结果:<span class="themefont" style="margin-left: 10px">不同意 </span>
            </p> -->
          </template>
        </div>
        <table class="list">
          <tr v-if='(order.getrefund||{}).add_time'>
            <td>申请时间：
            </td>
            <td>{{order.getrefund.add_time}}</td>
          </tr>
          <tr v-if='order.getrefund.admin_time'>
            <td>商家处理时间：</td>

            <td>{{order.getrefund.admin_time}}</td>
          </tr>
          <tr v-if='order.getrefund.ship_time'>
            <td>买家发货时间：</td>

            <td>{{order.getrefund.ship_time}}</td>
          </tr>
          <tr v-if='order.getrefund.money_return_time'>
            <td>退款时间：</td>

            <td>{{order.getrefund.money_return_time }}</td>
          </tr>

        </table>
        <el-button style="margin-left:200px" type="primary" @click="$router.push('/member-order')">
          返回
        </el-button>
      </div>

      <div class="detail">
        <p>订单详情
        </p>

        <table class="list">
          <tr>
            <td>卖家：</td>
            <td>
              {{ order.shop_name }}
            </td>
          </tr>
          <tr>
            <td>联系电话：</td>
            <td>
              {{ order.telephone }}
            </td>
          </tr>
          <tr>
            <td>订单编号：</td>
            <td>
              {{ order.out_request_no }}
            </td>
          </tr>

          <tr>
            <td>邮费：</td>
            <td>
              {{ order.shipping_money }}元
            </td>
          </tr>
          <tr>
            <td>订单总价：</td>
            <td>
              ￥{{ refund1.price }}
            </td>
          </tr>

          <tr>
            <td>下单时间：
            </td>
            <td>{{order.date_added}}</td>
          </tr>
          <tr v-if='order.pay_date'>
            <td>付款时间：</td>

            <td>{{order.pay_date}}</td>
          </tr>
          <tr v-if='order.shipping_date'>
            <td>商家发货时间：</td>

            <td>{{order.shipping_date}}</td>
          </tr>
        </table>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      refund_amount: 0,
      img: "",
      img2: "",

      srcList1: [],

      srcList: [],
      refund: {},
      countdown: undefined,
      files: undefined,
      logistics1: [],
      pic_info: [],
      refund_type: 1,
      return_type: 1,
      str: undefined,
      num: undefined,
      imgId: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      menuItems1: [],
      goods: [],
      order: {
        get_ordernum: {},
        getrefund: {}
      },
      id: "",
      method: {
        name: "自行寄回",
        id: "0"
      },
      baseUrl: this.$config.baseUrl,
      refund1: {
        files: [],
        reason_id: "",

        method: {
          name: "自行寄回",
          id: "0"
        },
        price: "",
        explain: "",
        avatar_url: ""
      },

      shipping_code: "",
      shipping_method: "",

      rules1: {
        reason_id: [
          {
            required: true,
            message: "请输入退款原因",
            trigger: "blur"
          }
        ],

        price: [
          {
            required: true,
            message: "请输入退款金额",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: {},
  mounted() {
    this.getShipping();
    this.refundReason();
  },
  watch: {},
  methods: {
    refundReason() {
      let data = {};
      if (this.return_type == 2) {
        data = { reason_type: 2 };
      } else {
        data = { reason_type: 1 };
      }
      this.$get("/home/refund_reason", data).then(res => {
        res.items.data.forEach(item => {
          this.menuItems1.push({
            id: item.reason_id,
            name: item.reason_info
          });
        });
      });
    },
    delRefund() {
      this.$del("home/refund_member/" + this.order.getrefund.refund_id).then(
        () => {
          this.$message.success("已撤销");
          this.$router.push({
            path: "/member-order"
          });
        }
      );
    },
    logistics(item) {
      this.url =
        "https://www.baidu.com/s?wd=" + item.getrefund.express_id + item.getrefund.invoice_no;

      window.open(this.url);
    },
    getShipping() {
      this.$get("admin/shipping").then(res => {
        this.logistics1 = res.items.map(i => {
          return {
            value: i.shipping_id,
            label: i.shipping_name
          };
        });
      });
    },
    submitForm2() {
      let data = {
        refund_id: this.order.getrefund.refund_id,
        invoice_no: this.shipping_code,
        express_id: this.shipping_method
      };

      console.log("data", data);
      if (!this.shipping_method) {
        this.$message.warning("请选择物流");
        return;
      }
      if (!this.shipping_code) {
        this.$message.warning("请填写物流单号");
        return;
      }

      this.$post("home/refund_member/buyersend", data).then(res => {
        this.$message.success("已提交");
        this.$router.push({
          path: "/member-order",
          query: { id: res }
        });
      });
    },

    submitForm1(formName) {
      console.log(this.files);

      let reason_name = "";
      this.menuItems1.forEach(o => {
        if (o.id == this.refund1.reason_id) reason_name = o.name;
      });
      let data = {
        order_id: this.order.order_master_id,
        zorder_id: this.order.order_id,
        zorder_sn: this.order.out_request_no,
        order_sn: this.order.get_ordernum.out_trade_no,
        store_id: this.goods.shop_id,
        store_name: this.order.shop_name,
        member_id: this.$store.state.member.id,
        member_name: this.$store.state.member.name,
        goods_id: this.goods.product_id,
        order_goods_id: this.goods.order_product_id,
        goods_name: this.goods.name,
        goods_price: this.refund1.price,
        goods_num: this.goods.quantity,
        refund_amount: this.refund_amount,
        goods_image: this.$config.baseUrl + this.goods.files_path,
        order_goods_type: 1, //接口问题先随意写个ID
        refund_type: (this.refund.return_type == 2 || this.order.order_status_id == 3) ? 2 : 1,
        return_type: (this.refund.return_type == 2 || this.order.order_status_id == 3) ? 2 : 1,
        goods_state: "",
        reason_id: this.refund1.reason_id,
        reason_info: reason_name,
        buyer_message: this.refund1.explain,
        pic_info: this.files.length ? this.files : null
      };
      console.log("data", data);
      if (data.refund_amount > this.order.total / 100) {
        this.$message.warning("退款金额不能大于实付金额");
        return;
      }
      if (data.refund_amount <= 0) {
        this.$message.warning("退款金额不能小于0");
        return;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$post("home/refund_member", data).then(res => {
            console.log("tk", res);
            this.$message.success("已提交退款申请");
            this.$router.push({
              path: "/member-order",
              query: { id: res }
            });
          });
        } else {
          return false;
        }
      });
    },

    handleRemove(file) {
      console.log("file3", file);
    },
    handlePictureCardPreview(file) {
      console.log("file1", file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log("file2", file);
    },
    //查询退货原因
    getReason() {
      let data = 1;
      if (this.return_type == 2) data = 2;
      this.$get("home/refund_reason", { reason_type: data }).then(res => {
        console.log("menuItems1", res.items);
        res.items.data.forEach(item => {
          this.menuItems1.push({
            id: item.reason_id,
            name: item.reason_info
          });
        });
      });
    },
    //获取订单详情信息

    getDetail() {
      if (!this.id) return;
      this.$get("home/order/" + this.id).then(res => {

        this.order = res;
 this.order.getrefund.seller_address = JSON.parse(
          res.getrefund.seller_address
        );
        this.goods = this.order.get_order_product[0];
        console.log("  this.goods ", this.goods);
        if (this.order.order_status_id == 13 && !this.order.shipping_code) {
          this.refund_amount = this.refund1.price = this.order.total / 100;
        } else {
          this.refund_amount = this.refund1.price =
            this.order.total / 100 - this.order.shipping_money;
        }
        if (res.getrefund) {
          this.$get("home/refund_member/" + res.getrefund.refund_id).then(
            ress => {
              this.refund = ress;
              if (this.refund.seller_state == 1) {
                this.countDown(this.refund.add_dates);
              }

              if (this.refund.seller_state == 5 && !this.refund.express_id) {
                this.countDown(this.refund.admin_time);
                console.log("this.refund.admin_time", this.refund.admin_time);
              }
            }
          );
        }

        if (this.order.getrefund) {
          if (this.order.getrefund.seller_pic) {
            this.srcList = this.order.getrefund.seller_pic
              .split(",")
              .map(o => this.$config.baseUrl + o);
          }
          this.img = this.srcList[0];
        }
        if (this.order.getrefund) {
          if (this.order.getrefund.pic_info) {
            this.srcList1 = this.order.getrefund.pic_info
              .split(",")
              .map(o => this.$config.baseUrl + o);
          }
          this.img2 = this.srcList1[0];
        }



        if (
          (this.order.getrefund || {}).seller_state == 1 &&
          (this.order.getrefund || {}).refund_type == 1
        ) {
          // this.num = "退款中";
          this.num = 2;

        }
        else if (
          (this.order.getrefund || {}).seller_state == 1 &&
          (this.order.getrefund || {}).refund_type == 2 &&
          !(this.order.getrefund || {}).express_id
        ) {
          // this.title = "申请退货退款中";
          this.num = 2;
        }

        else if (
          ((this.order.getrefund || {}).refund_state == 5 ||
            (this.order.getrefund || {}).seller_state == 3) &&
          (this.order.getrefund || {}).refund_type == 1
        ) {
          // this.title = "拒绝退款";
          this.num = 3;
        }
        else if ((this.order.getrefund || {}).refund_state == 4) {
          // this.title = "已退款";
          this.num = 3;
        }
        else if (
          ((this.order.getrefund || {}).seller_state == 5 ||
            (this.order.getrefund || {}).refund_state == 6) &&
          (this.order.getrefund || {}).refund_type == 2 &&
          !(this.order.getrefund || {}).express_id
        ) {
          // this.title = "待退货";
          this.num = 3;
        }
        else if (

          (this.order.getrefund || {}).seller_state == 3 &&
          (this.order.getrefund || {}).refund_type == 2 &&
          this.order.order_status_id != 5 && this.order.order_status_id != 6

        ) {
          // this.title = "卖家拒绝退货";
          this.num = 3;
        }
        else if (
          (this.order.getrefund || {}).express_id &&
          (this.order.getrefund || {}).seller_state == 5
        ) {
          // this.title = "买家已退货，待签收";
          this.num = 3;
        }
        else if (

          (this.order.getrefund || {}).seller_state == 7 && (this.order.getrefund || {}).refund_type == 2 && !(this.order.order_status_id == 5 || this.order.order_status_id == 6)

        ) {
          // this.num = " 已退货，拒绝退款";
          this.num = 3;
        }
        else {
          this.num = 1
        }


      });
    },
    countDown(date) {
      let now = new Date();
      let end = new Date(date).addHours(48);

      let countdown = "";
      let diffDay = this.$util.getDateDiff(now, end, "dd");
      if (diffDay > 0) {
        now = now.addDates(diffDay);
        countdown += `${diffDay}天`;
      }

      let diffHour = this.$util.getDateDiff(now, end, "HH");
      if (diffHour > 0) {
        now = now.addHours(diffHour);
        countdown += `${diffHour}小时`;
      }

      let diffMinute = this.$util.getDateDiff(now, end, "mm");
      if (diffMinute > 0) {
        now = now.addMinutes(diffMinute);
        countdown += `${diffMinute}分`;
      }

      this.countdown = countdown ? "还剩" + countdown : "已超期";
    }
  },
  created() {
    this.getReason();
    this.id = this.$route.query.id;
    // this.num = parseInt(this.$route.query.num);


    this.getDetail();
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/mixins";
.refund {
  padding-top: 20px;

  background: #fff;
  margin-top: 10px;
  .item {
    padding: 10px 0;
    @include space-between;
    .item-img-wraper {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      display: inline-block;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-info {
      p {
        line-height: 25px;
      }
      flex: 1;
      .about {
        color: #999;
      }
    }
  }
  @include space-between;
  .refunding {
    padding: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    width: 70%;
    p {
      margin-bottom: 10px;
    }
  }
  .refund1 {
    padding-right: 20px;
    box-sizing: border-box;
    width: 70%;
    .item {
      padding: 10px 0;
      @include space-between;
      .item-img-wraper {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-info {
        p {
          line-height: 25px;
        }
        flex: 1;
        .about {
          color: #999;
        }
      }
    }
  }
  .detail {
    flex: 1;
    .list {
      line-height: 30px;
    }
  }
}
::v-deep .el-step__title.is-process {
  font-weight: normal !important;
  color: #c0c4cc !important;
}
::v-deep .el-step__head.is-process {
  color: #c0c4cc !important;
  border-color: #c0c4cc !important;
}
::v-deep .el-steps--simple {
  background: #eee !important;
}
.result {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.list {
  margin-bottom: 10px;
  td {
    padding: 5px;
  }
}
</style>
